<template>
    <b-row>
        <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="shadow-sm"
                size="sm"
                variant="primary"
                v-if="page > 1"
                @click="onPrev()"
            >
                <feather-icon icon="ChevronLeftIcon"/>
                <span class="mobile-layout">&nbsp; {{translate.translate("key", "preduyushaya")}}</span>
            </b-button>
        </b-col>
        <b-col class="text-right">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="shadow-sm"
                size="sm"
                variant="primary"
                v-if="length >= limit"
                @click="onNext()"
            >
                <span
                    class="mobile-layout">{{translate.translate("key", "sleduyushaya")}} &nbsp;</span>
                <feather-icon icon="ChevronRightIcon"/>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import translate from "@/translation/translate";
import Ripple from "vue-ripple-directive";

export default {
    name: "Pagination",
    props: ["page", "length", "limit"],
    directives: {
        Ripple,
    },
    data() {
        return {
            translate
        }
    },
    methods: {
        onPrev() {
            this.$emit("prevPage");
        },

        onNext() {
            this.$emit("nextPage");
        },
    },
}
</script>

<style scoped>

</style>
