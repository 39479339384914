<template>
    <div>
        <b-dropdown class="mb-2 w-100" menu-class="w-100" split-class="w-100" :variant="selectedStatus.variant" right
                    split
        >
            <template v-slot:button-content>
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        {{ selectedStatus.label }}
                    </div>
                    <div> {{ count }} шт</div>
                </div>
            </template>
            <b-dropdown-item v-for="(item, index) in FilterOrderStatuses" v-if="item.value !== selectedStatus.value"
                             @click="onChangeStatus(item)" :key="index"
            >{{ item.label }}
            </b-dropdown-item>
        </b-dropdown>
        <div class="datepicker-filter mb-2">
            <datepicker
                ref="startDate"
                v-model="startDate"
                :placeholder='translate.translate("key", "nachalo")'
                :language="lang"
                :monday-first="true"
                :clear-button="true"
                @input="update"
            />
        </div>
        <div class="datepicker-filter mb-2">
            <datepicker
                ref="endDate"
                v-model="endDate"
                :placeholder='translate.translate("key", "konets")'
                :language="lang"
                :monday-first="true"
                :clear-button="true"
                @input="update"
            />
        </div>

        <div class="mb-2">
            <b-input placeholder="Поиск по номеру заказа"
                     v-model="search"
                     trim
                     size="sm"
                     @input="searchDebounce"
            />
        </div>

        <b-card v-for="(order, index) in orders" :key="index" no-body>
            <div class="p-1">
                <div class="d-flex justify-content-between">
                    <h5 class="mr-1 min-w-35">{{ (page - 1) * limit + index + 1 }}) {{translate.translate('key', 'klient') }}:</h5>
                    <h5 class="text-info text-right">{{ order.contractor.name }}</h5>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>{{ translate.translate('key', 'nomerZakaza') }}:</div>
                    <b-link :to="{ name: 'user-order-view', params: { id: order.id }}">
                        <h6>{{ order.order.number }}</h6>
                    </b-link>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>{{ translate.translate('key', 'obshayaSumma') }}:</div>
                    <div>
                        <h5><span class="text-warning">{{ totalNational(order.items) | decimal }}</span> сум</h5>
                        <h6 v-if="totalCurrency(order.items) > 0">{{ totalCurrency(order.items) | decimal }} </h6>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Товары:</div>
                    <div>
                        <h6>{{ order.items.length }} шт</h6>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Статус:</div>
                    <div>
                        <b-badge pill :variant="'light-'+getOrderStatusVariant(order.status)">
                            {{ translate.translate('key', getOrderStatusText(order.status)) }}
                        </b-badge>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>Дата создания:</div>
                    <div>
                        <h6>{{ order.created_at | datetime }}</h6>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>{{ translate.translate('key', 'dataZakaza') }}:</div>
                    <div>
                        <h6>{{ order.order.date | date }}</h6>
                    </div>
                </div>
            </div>
            <div class="text-right w-100">
                <b-button class="w-100" variant="outline-info" size="sm"
                          :to="{ name: 'user-order-view', params: { id: order.id }}"
                >Посмотреть заказ
                    <feather-icon icon="ArrowRightIcon"/>
                </b-button>
            </div>
        </b-card>

        <h5 class="text-center" v-if="!loading && orders.length === 0">
            {{ translate.translate('key', 'povashemuZaprosuNeNayden') }}</h5>
        <Pagination :page="page" :limit="limit" :length="orders.length" @prevPage="onPrev" @nextPage="onNext"/>
    </div>
</template>

<script>
import TableLimit from '@/components/TableLimit'
import translate from '@/translation/translate'
import Pagination from '@/components/Pagination'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
    getOrderStatusText,
    getOrderStatusVariant,
} from '@/constants/order-status'
import OrderService from '../../../../services/order.service'
import UserOrderView from '@/views/app/user/order/UserOrderView'
import { FilterOrderStatuses } from '@/constants/order-status'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import { _ } from 'vue-underscore'

export default {
    name: 'UserOrders',
    components: {
        UserOrderView,
        Pagination,
        TableLimit,
        Datepicker
    },
    data() {
        return {
            lang: ru,
            FilterOrderStatuses,
            loading: false,
            search: this.$route.query && this.$route.query.search ? this.$route.query.search : '',
            page: this.$route.query && this.$route.query.page ? +this.$route.query.page : 1,
            limit: localStorage.getItem('limit') || '10',
            count: 0,
            orders: 0,
            translate,
            fields: [
                '#',
                {
                    key: 'order_number',
                    label: translate.translate('key', 'nomerZakaza')
                },
                {
                    key: 'total',
                    label: translate.translate('key', 'summa'),
                    class: ['min-width-180', 'text-right']
                },
                {
                    key: 'status',
                    label: 'Статус',
                    class: ['min-width-180', 'text-right']
                },
                {
                    key: 'created_at',
                    label: translate.translate('key', 'data')
                },
            ],
            selectedStatus: FilterOrderStatuses.find(status => status.value === +this.$route.query.status) || FilterOrderStatuses[0],
            startDate: this.$route.query && this.$route.query.startDate ? new Date(+this.$route.query.startDate) : null,
            endDate: this.$route.query && this.$route.query.endDate ? new Date(+this.$route.query.endDate) : null,
        }
    },
    computed: {
        totalAmount() {
            let amount = 0
            this.orders.forEach(order => {
                let total = order.items.reduce((total, order_item) => {
                    return total + (order_item.price_amount * order_item.quantity)
                }, 0)
                amount = amount + total
            })
            return amount
        },
    },
    methods: {
        searchDebounce: _.debounce(function () {
            this.update()
        }, 1000),
        onChangeStatus(status) {
            this.page = 1
            this.selectedStatus = status
            this.update()
        },
        getOrderStatusText,
        getOrderStatusVariant,
        onPrev() {
            this.page = this.page - 1
            this.update()
        },
        onNext() {
            this.page = this.page + 1
            this.update()
        },
        onChangeLimit(selectedLimit) {
            this.limit = selectedLimit
            this.page = 1
            this.update()
        },
        update() {
            this.updateRouteQuery({
                page: this.page,
                limit: this.limit,
                status: this.selectedStatus ? this.selectedStatus.value : null,
                startDate: this.startDate ? this.startDate.getTime() : null,
                endDate: this.endDate ? this.endDate.getTime() : null,
                search: this.search ? this.search : null,
            })
            this.loadUserOrders()
            this.loadOrdersCount()
        },
        updateRouteQuery({
            page,
            limit,
            status,
            startDate,
            endDate,
            search
        }) {
            let routeQuery = {}
            if (page) {
                routeQuery.page = page
            }
            if (limit) {
                routeQuery.limit = limit
            }
            if (status) {
                routeQuery.status = status
            }
            if (startDate) {
                routeQuery.startDate = startDate
            }
            if (endDate) {
                routeQuery.endDate = endDate
            }
            if (search) {
                routeQuery.search = search
            }
            this.$router.replace({
                name: this.$route.name,
                query: routeQuery
            })
                .catch(err => {
                })
        },
        totalNational(order_items) {
            return order_items.reduce((total, order_item) => {
                if (order_item.is_national) {
                    return total + (order_item.price_amount * order_item.quantity)
                } else {
                    return total
                }
            }, 0)
        },
        totalCurrency(order_items) {
            return order_items.reduce((total, order_item) => {
                if (!order_item.is_national) {
                    return total + (order_item.price_amount * order_item.quantity)
                } else {
                    return total
                }
            }, 0)
        },
        loadUserOrders() {
            this.loading = true
            OrderService.getAllOrders({
                skip: (this.page - 1) * +this.limit,
                limit: this.limit,
                status: this.selectedStatus ? this.selectedStatus.value : null,
                date_start: this.$options.filters.api_date_format(this.startDate) || null,
                date_end: this.$options.filters.api_date_format(this.endDate) || null,
                order_number: this.search || ''
            })
                .then(res => {
                    if (res && res.data) {
                        this.orders = res.data
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Возникла ошибка',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Возникла ошибка',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadOrdersCount() {
            this.loading = true
            OrderService.getAllOrdersCount({
                status: this.selectedStatus ? this.selectedStatus.value : null,
                date_start: this.$options.filters.api_date_format(this.startDate) || null,
                date_end: this.$options.filters.api_date_format(this.endDate) || null,
                order_number: this.search || ''
            })
                .then(res => {
                    if (res) {
                        this.count = res.data
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Возникла ошибка',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Возникла ошибка',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.loadUserOrders()
        this.loadOrdersCount()
    },
}
</script>

<style>
</style>
